import React, { useCallback } from 'react';
import {
  Document,
  Page,
  Text,
  View,
  Image,
  Svg,
  Path,
} from '@react-pdf/renderer';
import { differenceInYears, parseISO } from 'date-fns';
import { Routes } from '../../../types/Routes';

import { styles } from './styles';

type FileProps = {
  routes: Routes[];
  week: number | null;
  date: string | null;
};

const File: React.FC<FileProps> = ({ routes, week, date }) => {
  let count = 0;
  let break_line = false;

  const handleTotal = useCallback((r: Routes[], num: number) => {
    let total = 0;

    r.forEach((rn) => {
      const r2 = rn.turns.filter((t) => {
        const numT =
          (t.household &&
            t.household.household_members &&
            t.household.household_members.length + 1) ||
          1;

        return numT === num;
      });

      total += r2.length;
    });

    return total;
  }, []);

  return (
    <Document>
      <Page size="A4" style={styles.page} orientation="landscape">
        <View style={styles.header}>
          <Image src="souma.png" style={styles.image} />
          <Text style={styles.text}>Mapa de Rotas</Text>
          {week && date && (
            <View style={styles.date_week}>
              <Text style={styles.text_week_date}>Semana: {week}</Text>
              <Text style={styles.text_week_date}>Data: {date}</Text>
            </View>
          )}
        </View>
        {routes
          .sort((a: Routes, b: Routes) =>
            a.routeNum.localeCompare(b.routeNum, undefined, { numeric: true }),
          )
          .map((route) => {
            if (break_line) {
              break_line = false;
            }

            const total = count + 2 + route.turns.length;

            if (total > 17) {
              count = 0;
              break_line = true;
            }

            count += 2 + route.turns.length;

            return (
              route.status && (
                <View key={route.id} break={break_line}>
                  <View style={styles.route_title}>
                    <Text style={styles.route_title_text}>
                      Rota {route.routeNum} -{' '}
                      {route.routeDayType === 7 && 'semanal'}
                      {route.routeDayType === 15 && 'quinzenal'}
                      {route.routeDayType === 30 && 'mensal'}
                    </Text>
                  </View>
                  <View style={styles.route_table_th}>
                    <Text
                      style={{
                        ...styles.route_table_th_text,
                        width: '2%',
                        textAlign: 'center',
                      }}
                    >
                      #
                    </Text>
                    <Text
                      style={{ ...styles.route_table_th_text, width: '14%' }}
                    >
                      Agregado
                    </Text>
                    <Text
                      style={{ ...styles.route_table_th_text, width: '18%' }}
                    >
                      Notas
                    </Text>
                    <Text
                      style={{
                        ...styles.route_table_th_text,
                        width: '5%',
                        textAlign: 'center',
                      }}
                    >
                      PAX
                    </Text>
                    <Text
                      style={{
                        ...styles.route_table_th_text,
                        width: '7%',
                        textAlign: 'center',
                      }}
                    >
                      Idades
                    </Text>
                    <Text
                      style={{
                        ...styles.route_table_th_text,
                        width: '5%',
                        textAlign: 'center',
                      }}
                    >
                      Cozinha?
                    </Text>
                    <Text
                      style={{
                        ...styles.route_table_th_text,
                        width: '8%',
                        textAlign: 'center',
                      }}
                    >
                      Nº Refeições
                    </Text>
                    <Text
                      style={{ ...styles.route_table_th_text, width: '18%' }}
                    >
                      Morada
                    </Text>
                    <Text
                      style={{ ...styles.route_table_th_text, width: '7%' }}
                    >
                      Localidade
                    </Text>
                    <Text
                      style={{
                        ...styles.route_table_th_text,
                        width: '15%',
                        textAlign: 'center',
                      }}
                    >
                      Contacto
                    </Text>
                  </View>
                  {route.turns
                    .sort(
                      (a, b) =>
                        new Date(a.createdAt).getTime() -
                        new Date(b.createdAt).getTime(),
                    )
                    .map((turn, index) => {
                      return (
                        <View key={turn.id} style={styles.route_table_td}>
                          <Text
                            style={{
                              ...styles.route_table_td_text,
                              width: '2%',
                              textAlign: 'center',
                            }}
                          >
                            {index + 1}
                          </Text>
                          <View
                            style={{
                              width: '14%',
                              maxWidth: '14%',
                              display: 'flex',
                              alignItems: 'center',
                              flexDirection: 'row',
                            }}
                          >
                            {turn.household?.market_session && turn.orderRef && (
                              <Svg
                                style={{
                                  width: '10px',
                                  height: '10px',
                                  marginRight: '2px',
                                }}
                                viewBox="0 0 24 24"
                              >
                                <Path d="M0 0h24v24H0V0z">
                                  <Path
                                    fill="#007970"
                                    d="M7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2zm10 0c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2zm-1.45-5c.75 0 1.41-.41 1.75-1.03l3.58-6.49c.37-.66-.11-1.48-.87-1.48H5.21l-.94-2H1v2h2l3.6 7.59-1.35 2.44C4.52 15.37 5.48 17 7 17h12v-2H7l1.1-2h7.45zM6.16 6h12.15l-2.76 5H8.53L6.16 6z"
                                  />
                                </Path>
                              </Svg>
                            )}

                            <Text
                              style={{
                                ...styles.route_table_td_text,
                                width: turn.household?.market_session
                                  ? '80%'
                                  : '100%',
                              }}
                            >
                              {turn.household && turn.household.name}
                              {turn.onceMonth && ' - (1 vez mês)'}
                            </Text>
                          </View>
                          <Text
                            style={{
                              ...styles.route_table_td_text,
                              width: '18%',
                            }}
                          >
                            {turn.note}
                          </Text>
                          <Text
                            style={{
                              ...styles.route_table_td_text,
                              width: '5%',
                              textAlign: 'center',
                            }}
                          >
                            {(turn.household &&
                              turn.household.household_members &&
                              turn.household.household_members.length + 1) ||
                              0}
                          </Text>
                          <Text
                            style={{
                              ...styles.route_table_td_text,
                              width: '8%',
                              textAlign: 'center',
                            }}
                          >
                            {`${
                              turn.household &&
                              differenceInYears(
                                new Date(),
                                parseISO(turn.household.user.birth_date),
                              )
                            },${
                              turn.household &&
                              turn.household.household_members &&
                              turn.household.household_members.map(
                                (member) =>
                                  member.user.birth_date &&
                                  `${differenceInYears(
                                    new Date(),
                                    parseISO(member.user.birth_date),
                                  )}`,
                              )
                            }`.replaceAll(',', ', ')}
                          </Text>
                          <Text
                            style={{
                              ...styles.route_table_td_text,
                              width: '5%',
                              textAlign: 'center',
                            }}
                          >
                            {turn.canCook ? 'Sim' : 'Não'}
                          </Text>
                          <Text
                            style={{
                              ...styles.route_table_td_text,
                              width: '8%',
                              textAlign: 'center',
                            }}
                          >
                            {turn.numMeal}
                          </Text>
                          <Text
                            style={{
                              ...styles.route_table_td_text,
                              width: '18%',
                            }}
                          >
                            {turn.household && turn.household.user.address}
                          </Text>
                          <Text
                            style={{
                              ...styles.route_table_td_text,
                              width: '7%',
                            }}
                          >
                            {turn.household && turn.household.user.location}
                          </Text>
                          <Text
                            style={{
                              ...styles.route_table_td_text,
                              width: '15%',
                              textAlign: 'center',
                              maxWidth: '15%',
                              flex: 1,
                            }}
                          >
                            {turn.household && turn.household.user.contact}
                          </Text>
                        </View>
                      );
                    })}
                </View>
              )
            );
          })}
      </Page>
      <Page size="A4" style={styles.page} orientation="landscape">
        <View
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'space-around',
            marginTop: '40px',
          }}
        >
          <View
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <View
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
              }}
            >
              <Svg
                style={{ width: '80px', height: '80px' }}
                viewBox="0 0 24 24"
              >
                <Path
                  fill="#007970"
                  d="M4,13c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2s-2,0.9-2,2C2,12.1,2.9,13,4,13z M5.13,14.1C4.76,14.04,4.39,14,4,14 c-0.99,0-1.93,0.21-2.78,0.58C0.48,14.9,0,15.62,0,16.43V18l4.5,0v-1.61C4.5,15.56,4.73,14.78,5.13,14.1z M20,13c1.1,0,2-0.9,2-2 c0-1.1-0.9-2-2-2s-2,0.9-2,2C18,12.1,18.9,13,20,13z M24,16.43c0-0.81-0.48-1.53-1.22-1.85C21.93,14.21,20.99,14,20,14 c-0.39,0-0.76,0.04-1.13,0.1c0.4,0.68,0.63,1.46,0.63,2.29V18l4.5,0V16.43z M16.24,13.65c-1.17-0.52-2.61-0.9-4.24-0.9 c-1.63,0-3.07,0.39-4.24,0.9C6.68,14.13,6,15.21,6,16.39V18h12v-1.61C18,15.21,17.32,14.13,16.24,13.65z M8.07,16 c0.09-0.23,0.13-0.39,0.91-0.69c0.97-0.38,1.99-0.56,3.02-0.56s2.05,0.18,3.02,0.56c0.77,0.3,0.81,0.46,0.91,0.69H8.07z M12,8 c0.55,0,1,0.45,1,1s-0.45,1-1,1s-1-0.45-1-1S11.45,8,12,8 M12,6c-1.66,0-3,1.34-3,3c0,1.66,1.34,3,3,3s3-1.34,3-3 C15,7.34,13.66,6,12,6L12,6z"
                />
              </Svg>
              <Text
                style={{
                  color: '#007970',
                  fontSize: '50px',
                  marginLeft: '10px',
                }}
              >
                1
              </Text>
            </View>
            <Text style={{ color: '#007970', fontSize: '30px' }}>
              {handleTotal(routes, 1)}
            </Text>
          </View>

          <View
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <View
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
              }}
            >
              <Svg
                style={{ width: '80px', height: '80px' }}
                viewBox="0 0 24 24"
              >
                <Path
                  fill="#007970"
                  d="M4,13c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2s-2,0.9-2,2C2,12.1,2.9,13,4,13z M5.13,14.1C4.76,14.04,4.39,14,4,14 c-0.99,0-1.93,0.21-2.78,0.58C0.48,14.9,0,15.62,0,16.43V18l4.5,0v-1.61C4.5,15.56,4.73,14.78,5.13,14.1z M20,13c1.1,0,2-0.9,2-2 c0-1.1-0.9-2-2-2s-2,0.9-2,2C18,12.1,18.9,13,20,13z M24,16.43c0-0.81-0.48-1.53-1.22-1.85C21.93,14.21,20.99,14,20,14 c-0.39,0-0.76,0.04-1.13,0.1c0.4,0.68,0.63,1.46,0.63,2.29V18l4.5,0V16.43z M16.24,13.65c-1.17-0.52-2.61-0.9-4.24-0.9 c-1.63,0-3.07,0.39-4.24,0.9C6.68,14.13,6,15.21,6,16.39V18h12v-1.61C18,15.21,17.32,14.13,16.24,13.65z M8.07,16 c0.09-0.23,0.13-0.39,0.91-0.69c0.97-0.38,1.99-0.56,3.02-0.56s2.05,0.18,3.02,0.56c0.77,0.3,0.81,0.46,0.91,0.69H8.07z M12,8 c0.55,0,1,0.45,1,1s-0.45,1-1,1s-1-0.45-1-1S11.45,8,12,8 M12,6c-1.66,0-3,1.34-3,3c0,1.66,1.34,3,3,3s3-1.34,3-3 C15,7.34,13.66,6,12,6L12,6z"
                />
              </Svg>
              <Text
                style={{
                  color: '#007970',
                  fontSize: '50px',
                  marginLeft: '10px',
                }}
              >
                2
              </Text>
            </View>
            <Text style={{ color: '#007970', fontSize: '30px' }}>
              {handleTotal(routes, 2)}
            </Text>
          </View>

          <View
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <View
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
              }}
            >
              <Svg
                style={{ width: '80px', height: '80px' }}
                viewBox="0 0 24 24"
              >
                <Path
                  fill="#007970"
                  d="M4,13c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2s-2,0.9-2,2C2,12.1,2.9,13,4,13z M5.13,14.1C4.76,14.04,4.39,14,4,14 c-0.99,0-1.93,0.21-2.78,0.58C0.48,14.9,0,15.62,0,16.43V18l4.5,0v-1.61C4.5,15.56,4.73,14.78,5.13,14.1z M20,13c1.1,0,2-0.9,2-2 c0-1.1-0.9-2-2-2s-2,0.9-2,2C18,12.1,18.9,13,20,13z M24,16.43c0-0.81-0.48-1.53-1.22-1.85C21.93,14.21,20.99,14,20,14 c-0.39,0-0.76,0.04-1.13,0.1c0.4,0.68,0.63,1.46,0.63,2.29V18l4.5,0V16.43z M16.24,13.65c-1.17-0.52-2.61-0.9-4.24-0.9 c-1.63,0-3.07,0.39-4.24,0.9C6.68,14.13,6,15.21,6,16.39V18h12v-1.61C18,15.21,17.32,14.13,16.24,13.65z M8.07,16 c0.09-0.23,0.13-0.39,0.91-0.69c0.97-0.38,1.99-0.56,3.02-0.56s2.05,0.18,3.02,0.56c0.77,0.3,0.81,0.46,0.91,0.69H8.07z M12,8 c0.55,0,1,0.45,1,1s-0.45,1-1,1s-1-0.45-1-1S11.45,8,12,8 M12,6c-1.66,0-3,1.34-3,3c0,1.66,1.34,3,3,3s3-1.34,3-3 C15,7.34,13.66,6,12,6L12,6z"
                />
              </Svg>
              <Text
                style={{
                  color: '#007970',
                  fontSize: '50px',
                  marginLeft: '10px',
                }}
              >
                3
              </Text>
            </View>
            <Text style={{ color: '#007970', fontSize: '30px' }}>
              {handleTotal(routes, 3)}
            </Text>
          </View>
        </View>

        <View
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'space-around',
            marginTop: '40px',
          }}
        >
          <View
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <View
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
              }}
            >
              <Svg
                style={{ width: '80px', height: '80px' }}
                viewBox="0 0 24 24"
              >
                <Path
                  fill="#007970"
                  d="M4,13c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2s-2,0.9-2,2C2,12.1,2.9,13,4,13z M5.13,14.1C4.76,14.04,4.39,14,4,14 c-0.99,0-1.93,0.21-2.78,0.58C0.48,14.9,0,15.62,0,16.43V18l4.5,0v-1.61C4.5,15.56,4.73,14.78,5.13,14.1z M20,13c1.1,0,2-0.9,2-2 c0-1.1-0.9-2-2-2s-2,0.9-2,2C18,12.1,18.9,13,20,13z M24,16.43c0-0.81-0.48-1.53-1.22-1.85C21.93,14.21,20.99,14,20,14 c-0.39,0-0.76,0.04-1.13,0.1c0.4,0.68,0.63,1.46,0.63,2.29V18l4.5,0V16.43z M16.24,13.65c-1.17-0.52-2.61-0.9-4.24-0.9 c-1.63,0-3.07,0.39-4.24,0.9C6.68,14.13,6,15.21,6,16.39V18h12v-1.61C18,15.21,17.32,14.13,16.24,13.65z M8.07,16 c0.09-0.23,0.13-0.39,0.91-0.69c0.97-0.38,1.99-0.56,3.02-0.56s2.05,0.18,3.02,0.56c0.77,0.3,0.81,0.46,0.91,0.69H8.07z M12,8 c0.55,0,1,0.45,1,1s-0.45,1-1,1s-1-0.45-1-1S11.45,8,12,8 M12,6c-1.66,0-3,1.34-3,3c0,1.66,1.34,3,3,3s3-1.34,3-3 C15,7.34,13.66,6,12,6L12,6z"
                />
              </Svg>
              <Text
                style={{
                  color: '#007970',
                  fontSize: '50px',
                  marginLeft: '10px',
                }}
              >
                4
              </Text>
            </View>
            <Text style={{ color: '#007970', fontSize: '30px' }}>
              {handleTotal(routes, 4)}
            </Text>
          </View>

          <View
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <View
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
              }}
            >
              <Svg
                style={{ width: '80px', height: '80px' }}
                viewBox="0 0 24 24"
              >
                <Path
                  fill="#007970"
                  d="M4,13c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2s-2,0.9-2,2C2,12.1,2.9,13,4,13z M5.13,14.1C4.76,14.04,4.39,14,4,14 c-0.99,0-1.93,0.21-2.78,0.58C0.48,14.9,0,15.62,0,16.43V18l4.5,0v-1.61C4.5,15.56,4.73,14.78,5.13,14.1z M20,13c1.1,0,2-0.9,2-2 c0-1.1-0.9-2-2-2s-2,0.9-2,2C18,12.1,18.9,13,20,13z M24,16.43c0-0.81-0.48-1.53-1.22-1.85C21.93,14.21,20.99,14,20,14 c-0.39,0-0.76,0.04-1.13,0.1c0.4,0.68,0.63,1.46,0.63,2.29V18l4.5,0V16.43z M16.24,13.65c-1.17-0.52-2.61-0.9-4.24-0.9 c-1.63,0-3.07,0.39-4.24,0.9C6.68,14.13,6,15.21,6,16.39V18h12v-1.61C18,15.21,17.32,14.13,16.24,13.65z M8.07,16 c0.09-0.23,0.13-0.39,0.91-0.69c0.97-0.38,1.99-0.56,3.02-0.56s2.05,0.18,3.02,0.56c0.77,0.3,0.81,0.46,0.91,0.69H8.07z M12,8 c0.55,0,1,0.45,1,1s-0.45,1-1,1s-1-0.45-1-1S11.45,8,12,8 M12,6c-1.66,0-3,1.34-3,3c0,1.66,1.34,3,3,3s3-1.34,3-3 C15,7.34,13.66,6,12,6L12,6z"
                />
              </Svg>
              <Text
                style={{
                  color: '#007970',
                  fontSize: '50px',
                  marginLeft: '10px',
                }}
              >
                5
              </Text>
            </View>
            <Text style={{ color: '#007970', fontSize: '30px' }}>
              {handleTotal(routes, 5)}
            </Text>
          </View>

          <View
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <View
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
              }}
            >
              <Svg
                style={{ width: '80px', height: '80px' }}
                viewBox="0 0 24 24"
              >
                <Path
                  fill="#007970"
                  d="M4,13c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2s-2,0.9-2,2C2,12.1,2.9,13,4,13z M5.13,14.1C4.76,14.04,4.39,14,4,14 c-0.99,0-1.93,0.21-2.78,0.58C0.48,14.9,0,15.62,0,16.43V18l4.5,0v-1.61C4.5,15.56,4.73,14.78,5.13,14.1z M20,13c1.1,0,2-0.9,2-2 c0-1.1-0.9-2-2-2s-2,0.9-2,2C18,12.1,18.9,13,20,13z M24,16.43c0-0.81-0.48-1.53-1.22-1.85C21.93,14.21,20.99,14,20,14 c-0.39,0-0.76,0.04-1.13,0.1c0.4,0.68,0.63,1.46,0.63,2.29V18l4.5,0V16.43z M16.24,13.65c-1.17-0.52-2.61-0.9-4.24-0.9 c-1.63,0-3.07,0.39-4.24,0.9C6.68,14.13,6,15.21,6,16.39V18h12v-1.61C18,15.21,17.32,14.13,16.24,13.65z M8.07,16 c0.09-0.23,0.13-0.39,0.91-0.69c0.97-0.38,1.99-0.56,3.02-0.56s2.05,0.18,3.02,0.56c0.77,0.3,0.81,0.46,0.91,0.69H8.07z M12,8 c0.55,0,1,0.45,1,1s-0.45,1-1,1s-1-0.45-1-1S11.45,8,12,8 M12,6c-1.66,0-3,1.34-3,3c0,1.66,1.34,3,3,3s3-1.34,3-3 C15,7.34,13.66,6,12,6L12,6z"
                />
              </Svg>
              <Text
                style={{
                  color: '#007970',
                  fontSize: '50px',
                  marginLeft: '10px',
                }}
              >
                6
              </Text>
            </View>
            <Text style={{ color: '#007970', fontSize: '30px' }}>
              {handleTotal(routes, 6)}
            </Text>
          </View>
        </View>

        <View
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'space-around',
            marginTop: '40px',
          }}
        >
          <View
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <View
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
              }}
            >
              <Svg
                style={{ width: '80px', height: '80px' }}
                viewBox="0 0 24 24"
              >
                <Path
                  fill="#007970"
                  d="M4,13c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2s-2,0.9-2,2C2,12.1,2.9,13,4,13z M5.13,14.1C4.76,14.04,4.39,14,4,14 c-0.99,0-1.93,0.21-2.78,0.58C0.48,14.9,0,15.62,0,16.43V18l4.5,0v-1.61C4.5,15.56,4.73,14.78,5.13,14.1z M20,13c1.1,0,2-0.9,2-2 c0-1.1-0.9-2-2-2s-2,0.9-2,2C18,12.1,18.9,13,20,13z M24,16.43c0-0.81-0.48-1.53-1.22-1.85C21.93,14.21,20.99,14,20,14 c-0.39,0-0.76,0.04-1.13,0.1c0.4,0.68,0.63,1.46,0.63,2.29V18l4.5,0V16.43z M16.24,13.65c-1.17-0.52-2.61-0.9-4.24-0.9 c-1.63,0-3.07,0.39-4.24,0.9C6.68,14.13,6,15.21,6,16.39V18h12v-1.61C18,15.21,17.32,14.13,16.24,13.65z M8.07,16 c0.09-0.23,0.13-0.39,0.91-0.69c0.97-0.38,1.99-0.56,3.02-0.56s2.05,0.18,3.02,0.56c0.77,0.3,0.81,0.46,0.91,0.69H8.07z M12,8 c0.55,0,1,0.45,1,1s-0.45,1-1,1s-1-0.45-1-1S11.45,8,12,8 M12,6c-1.66,0-3,1.34-3,3c0,1.66,1.34,3,3,3s3-1.34,3-3 C15,7.34,13.66,6,12,6L12,6z"
                />
              </Svg>
              <Text
                style={{
                  color: '#007970',
                  fontSize: '50px',
                  marginLeft: '10px',
                }}
              >
                7
              </Text>
            </View>
            <Text style={{ color: '#007970', fontSize: '30px' }}>
              {handleTotal(routes, 7)}
            </Text>
          </View>

          <View
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <View
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
              }}
            >
              <Svg
                style={{ width: '80px', height: '80px' }}
                viewBox="0 0 24 24"
              >
                <Path
                  fill="#007970"
                  d="M4,13c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2s-2,0.9-2,2C2,12.1,2.9,13,4,13z M5.13,14.1C4.76,14.04,4.39,14,4,14 c-0.99,0-1.93,0.21-2.78,0.58C0.48,14.9,0,15.62,0,16.43V18l4.5,0v-1.61C4.5,15.56,4.73,14.78,5.13,14.1z M20,13c1.1,0,2-0.9,2-2 c0-1.1-0.9-2-2-2s-2,0.9-2,2C18,12.1,18.9,13,20,13z M24,16.43c0-0.81-0.48-1.53-1.22-1.85C21.93,14.21,20.99,14,20,14 c-0.39,0-0.76,0.04-1.13,0.1c0.4,0.68,0.63,1.46,0.63,2.29V18l4.5,0V16.43z M16.24,13.65c-1.17-0.52-2.61-0.9-4.24-0.9 c-1.63,0-3.07,0.39-4.24,0.9C6.68,14.13,6,15.21,6,16.39V18h12v-1.61C18,15.21,17.32,14.13,16.24,13.65z M8.07,16 c0.09-0.23,0.13-0.39,0.91-0.69c0.97-0.38,1.99-0.56,3.02-0.56s2.05,0.18,3.02,0.56c0.77,0.3,0.81,0.46,0.91,0.69H8.07z M12,8 c0.55,0,1,0.45,1,1s-0.45,1-1,1s-1-0.45-1-1S11.45,8,12,8 M12,6c-1.66,0-3,1.34-3,3c0,1.66,1.34,3,3,3s3-1.34,3-3 C15,7.34,13.66,6,12,6L12,6z"
                />
              </Svg>
              <Text
                style={{
                  color: '#007970',
                  fontSize: '50px',
                  marginLeft: '10px',
                }}
              >
                8
              </Text>
            </View>
            <Text style={{ color: '#007970', fontSize: '30px' }}>
              {handleTotal(routes, 8)}
            </Text>
          </View>

          <View
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <View
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
              }}
            >
              <Svg
                style={{ width: '80px', height: '80px' }}
                viewBox="0 0 24 24"
              >
                <Path
                  fill="#007970"
                  d="M4,13c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2s-2,0.9-2,2C2,12.1,2.9,13,4,13z M5.13,14.1C4.76,14.04,4.39,14,4,14 c-0.99,0-1.93,0.21-2.78,0.58C0.48,14.9,0,15.62,0,16.43V18l4.5,0v-1.61C4.5,15.56,4.73,14.78,5.13,14.1z M20,13c1.1,0,2-0.9,2-2 c0-1.1-0.9-2-2-2s-2,0.9-2,2C18,12.1,18.9,13,20,13z M24,16.43c0-0.81-0.48-1.53-1.22-1.85C21.93,14.21,20.99,14,20,14 c-0.39,0-0.76,0.04-1.13,0.1c0.4,0.68,0.63,1.46,0.63,2.29V18l4.5,0V16.43z M16.24,13.65c-1.17-0.52-2.61-0.9-4.24-0.9 c-1.63,0-3.07,0.39-4.24,0.9C6.68,14.13,6,15.21,6,16.39V18h12v-1.61C18,15.21,17.32,14.13,16.24,13.65z M8.07,16 c0.09-0.23,0.13-0.39,0.91-0.69c0.97-0.38,1.99-0.56,3.02-0.56s2.05,0.18,3.02,0.56c0.77,0.3,0.81,0.46,0.91,0.69H8.07z M12,8 c0.55,0,1,0.45,1,1s-0.45,1-1,1s-1-0.45-1-1S11.45,8,12,8 M12,6c-1.66,0-3,1.34-3,3c0,1.66,1.34,3,3,3s3-1.34,3-3 C15,7.34,13.66,6,12,6L12,6z"
                />
              </Svg>
              <Text
                style={{
                  color: '#007970',
                  fontSize: '50px',
                  marginLeft: '10px',
                }}
              >
                9
              </Text>
            </View>
            <Text style={{ color: '#007970', fontSize: '30px' }}>
              {handleTotal(routes, 9)}
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default File;
